import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "../../../Utils";
import moment from "moment";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";

const BlockUser = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchBlockUser, setsearchBlockUser] = useState("");
  const [BlockUserListPagination, setBlockUserListPagination] = useState([]);

  const GetBlockUserList = async () => {
    setIsLoading(true);
    await getNodeApi(
      `user/block-user?offset=${page}&limit=${limit}&search=${searchBlockUser}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.totalRecords > 0) {
          setBlockUserListPagination(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setBlockUserListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setBlockUserListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        throw err;
      });
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setsearchBlockUser(searchValue);
    } else {
      setsearchBlockUser("");
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetBlockUserList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetBlockUserList(parseInt(event.target.value, 10), 0, "");
  };

  useEffect(() => {
    GetBlockUserList(limit, page, searchBlockUser);
  }, [limit, page, searchBlockUser]);

  return (
    <>
      <PageTitle pageTitle="Block User List" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Block User</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Block User</li>
                </ul>
              </div>
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                placeholder="Search Block User"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>User Name</th>
                          <th>Block User Name</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {BlockUserListPagination.map((user, index) => (
                              <tr key={index}>
                                <td>{user?.user?.full_name || "-"}</td>
                                <td>{user?.block_user?.full_name || "-"}</td>
                                <td>
                                  {moment(user?.createdAt).format(
                                    "D-MMM-yy hh:mm A"
                                  ) || "-"}
                                </td>
                              </tr>
                            ))}
                            {BlockUserListPagination.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={12}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockUser;
