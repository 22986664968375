import { Route, Routes, useNavigate } from "react-router-dom";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import {
  AddRoleManagement,
  EditRolePermission,
  Role,
} from "../Components/Admin/RoleManagement";
import {
  Admin,
  AddAdmin,
  EditAdmin,
} from "../Components/Admin/AdminManagement";
import { User, BlockUser, ReportUser } from "../Components/Admin/User";
import { Post, PostDetail } from "../Components/Admin/Post";
import { Dashboard } from "../Components/Admin/Dashboard";
import { ChangePassword, Settings } from "../Components/Admin/settings";
import { Confirmation, ErrorToast } from "../Utils/SweetAlert";
import { useEffect } from "react";
import usePermission from "../Hooks/useHasPermisson";
import { ContactUs, Feedbacks } from "../Components/Admin/CustomerServices";

export const Logout = (event) => {
  event.preventDefault();
  Confirmation("Are You Sure To Logout").then(async (result) => {
    if (result.isConfirmed) {
      try {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("adminPermission");
        localStorage.removeItem("userType");
        window.location.assign("/");
      } catch (err) {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      }
    }
  });
};

const AdminRoutes = () => {
  const navigate = useNavigate();
  const { token } = usePermission();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <AdminLayout />
      <Routes>
        <Route
          exact
          path="/"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/sub-admin"
          element={<PrivateRoute Component={Admin} />}
        />
        <Route
          exact
          path="/add-subadmin"
          element={<PrivateRoute Component={AddAdmin} />}
        />
        <Route
          exact
          path="/edit-subadmin/:id"
          element={<PrivateRoute Component={EditAdmin} />}
        />

        <Route exact path="/user" element={<PrivateRoute Component={User} />} />

        <Route
          exact
          path="/report-user"
          element={<PrivateRoute Component={ReportUser} />}
        />

        <Route
          exact
          path="/block-user"
          element={<PrivateRoute Component={BlockUser} />}
        />

        <Route
          exact
          path="/contact-us"
          element={<PrivateRoute Component={ContactUs} />}
        />

        <Route
          exact
          path="/feedback"
          element={<PrivateRoute Component={Feedbacks} />}
        />

        <Route exact path="/post" element={<PrivateRoute Component={Post} />} />

        <Route
          exact
          path="/post/:id"
          element={<PrivateRoute Component={PostDetail} />}
        />

        <Route exact path="/role" element={<PrivateRoute Component={Role} />} />

        <Route
          exact
          path="/add-role-management"
          element={<PrivateRoute Component={AddRoleManagement} />}
        />

        <Route
          exact
          path="/edit-role-permission/:id"
          element={<PrivateRoute Component={EditRolePermission} />}
        />

        <Route
          exact
          path="/profile"
          element={<PrivateRoute Component={Settings} />}
        />

        <Route
          exact
          path="/setting/change-password"
          element={<PrivateRoute Component={ChangePassword} />}
        />

        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
};
export default AdminRoutes;
