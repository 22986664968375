import React from "react";
import { Helmet } from "react-helmet";
import { EnvVariable } from "./constance/options";

const PageTitle = ({ pageTitle }) => {
  return (
    <Helmet>
      <title>
        {EnvVariable.AAPNAME} | {pageTitle}
      </title>
    </Helmet>
  );
};

export default PageTitle;
