import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SideMenu from "./SideMenu";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";

const Settings = () => {
  const [profileData, setProfileData] = useState({});
  const [selectedImage, setSelectedImage] = useState("");

  const initialValues = {
    email: profileData?.email || "",
    first_name: profileData?.first_name || "",
    last_name: profileData?.last_name || "",
    address: profileData?.address || "",
    profile_picture: profileData?.profile_picture || "",
  };

  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address: Yup.string().required("Address is required"),
  });

  const getProfileData = async () => {
    getNodeApi("auth/get-profile")
      .then((res) => {
        let data = res?.data || {};
        setProfileData(data);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <PageTitle pageTitle="Admin settings" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">Settings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SideMenu />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Basic information</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      const profileFile = values?.profile_picture;
                      let profile_img = null;
                      if (typeof profileFile == "object") {
                        const formData = new FormData();
                        formData.append("image", profileFile);
                        formData.append("module", "PROFILE_PICTURE");
                        await getImageUrl(formData).then((res) => {
                          profile_img = res.data.data[0].s3_key;
                        });
                      }
                      const data = {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        address: values.address,
                        profile_picture:
                          typeof profileFile == "object"
                            ? profile_img
                            : profileFile,
                      };
                      await putNodeApi("auth/update-profile", data)
                        .then((res) => {
                          SuccessToast(res?.message);
                          setSubmitting(false);
                          getNodeApi("auth/get-profile")
                            .then((res) => {
                              setProfileData(res.data);
                              window.location.reload();
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                            });
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <div className="row form-group">
                            <div className="col-sm-12">
                              <div className="d-flex align-items-center responsive-icon-center">
                                <label className="avatar avatar-xxl profile-cover-avatar m-2 ">
                                  <img
                                    className="avatar-img center-pro"
                                    src={
                                      typeof values.profile_picture === "object"
                                        ? selectedImage
                                        : profileData?.profile_picture
                                        ? `${process.env.REACT_APP_API_IMAGE_URL}${profileData.profile_picture}`
                                        : "/inner-assets/img/profiles/default_image.jpg"
                                    }
                                    alt="Profile-Imagie"
                                  />
                                  <input
                                    type="file"
                                    name="profile_picture"
                                    accept="image/*"
                                    onChange={(event) => {
                                      const selectedFile =
                                        event.currentTarget.files[0];
                                      if (selectedFile) {
                                        const fileExtension = selectedFile.name
                                          .split(".")
                                          .pop()
                                          .toLowerCase();
                                        if (
                                          ["jpeg", "jpg", "png"].includes(
                                            fileExtension
                                          )
                                        ) {
                                          setSelectedImage(
                                            URL.createObjectURL(selectedFile)
                                          );
                                          setFieldValue(
                                            "profile_picture",
                                            selectedFile
                                          );
                                        } else {
                                          ErrorToast(
                                            "Invalid file type. Please select a JPEG, JPG, or PNG file."
                                          );
                                        }
                                      }
                                    }}
                                  />
                                  <span className="avatar-edit">
                                    <i
                                      data-feather="edit-2"
                                      className="fa fa-pencil-alt shadow-soft"
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.first_name && touched.first_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="first_name"
                                  placeholder="Enter name"
                                  name="first_name"
                                  onChange={handleChange}
                                  value={values.first_name}
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="email"
                                  placeholder="Enter email"
                                  name="email"
                                  onChange={handleChange}
                                  value={values.email}
                                  disabled
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.last_name && touched.last_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="last_name"
                                  placeholder="Enter Last Name"
                                  name="last_name"
                                  onChange={handleChange}
                                  value={values.last_name}
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Address <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-12">
                                  <Field
                                    type="text"
                                    className={`form-control ${
                                      errors.address && touched.address
                                        ? "input-error"
                                        : null
                                    }`}
                                    id="addressline"
                                    placeholder="Enter address"
                                    name="address"
                                    onChange={handleChange}
                                    value={values.address}
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Save Changes&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
