import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Confirmation, RejectTextArea } from "../../../Utils/SweetAlert";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";
import DateRangeFilter from "../../../Utils/DateRangeFilter";
import { StyledMenu } from "../../../Utils";
import moment from "moment";

const Post = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPost, setSearchPost] = useState("");
  const [PostList, setPostList] = useState([]);
  const [openCal, setOpenCal] = useState(false);
  const [passDate, setPassDate] = useState("");
  const [resetDate, setResetDate] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      setOpenCal(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setOpenCal(false);
    }
    const formatDate = (date) => moment(date).format("YYYY-MM-DD");
    const endDate =
      ranges.endDate === null
        ? formatDate(ranges.startDate)
        : formatDate(ranges.endDate);
    const startDate = ranges.startDate ? formatDate(ranges.startDate) : "";
    setPassDate(startDate + "," + endDate);
  };

  const GetPostList = async () => {
    setIsLoading(true);
    await getNodeApi(
      `post?offset=${page}&limit=${limit}&search=${searchPost}&filter_date=${passDate}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.totalRecords > 0) {
          setPostList(res?.data);
          setTotalRecords(res?.totalRecords);
          setPassDate("");
          setResetDate(0)
        } else {
          setPostList([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setPostList([]);
        setTotalRecords(0);
        setIsLoading(false);
        throw err;
      });
  };

  useEffect(() => {
    if (passDate) {
      GetPostList(limit, page, searchPost, passDate);
    }
  }, [passDate]);

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchPost(searchValue);
    } else {
      setSearchPost("");
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetPostList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetPostList(parseInt(event.target.value, 10), 0, "");
  };

  const approvePost = (postId, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: postId,
          status: 2,
          reason: "",
        };
        setIsLoading(true);
        await putNodeApi("post/change-status", postObj);
        GetPostList(limit, page, searchPost);
      }
    });
  };

  const rejectPost = (postId, text) => {
    RejectTextArea({ denyButtonText: "Cancel" }).then(async (result) => {
      if (result.isConfirmed) {
        const rejectData = {
          id: postId,
          status: 3,
          reason: result.value,
        };
        setIsLoading(true);
        await putNodeApi("post/change-status", rejectData);
        GetPostList(limit, page, searchPost);
      }
    });
  };

  useEffect(() => {
    GetPostList(limit, page, searchPost);
  }, [limit, page, searchPost]);

  return (
    <>
      <PageTitle pageTitle="Post List" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Post</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Post</li>
                </ul>
              </div>
            </div>
            <div className="form-custom">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="multipleSelection">
                  <DateRangeFilter
                    onChange={onChange}
                    openCal={openCal}
                    setOpenCal={setOpenCal}
                    resetDate={resetDate}
                  />
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  ></StyledMenu>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Post"
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Status</th>
                          <th>Name</th>
                          <th>URL</th>
                          <th>Image</th>
                          <th>Category</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {PostList.map((post, index) => (
                              <tr key={index}>
                                <td>
                                  {post.status === 3 && (
                                    <>
                                      <span
                                        className="badge badge-pill bg-danger-light"
                                        style={{
                                          paddingLeft: "30px",
                                          paddingRight: "30px",
                                        }}
                                      >
                                        Rejected
                                      </span>
                                      {post?.reason && (
                                        <>
                                          <Tooltip title={post?.reason}>
                                            <IconButton
                                              style={{ color: "#ed4646" }}
                                            >
                                              <InfoIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {post.status === 2 && (
                                    <>
                                      <span className="badge badge-pill bg-success-light">
                                        Approved
                                      </span>
                                    </>
                                  )}
                                  {post.status === 1 && (
                                    <>
                                      <span
                                        className="btn btn-sm btn-block btn-outline-success"
                                        onClick={() => {
                                          approvePost(
                                            post?.id,
                                            "Want to Approve Post?"
                                          );
                                        }}
                                      >
                                        Approve{" "}
                                      </span>
                                      <span
                                        className="btn btn-sm btn-block btn-outline-danger"
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                          rejectPost(
                                            post?.id,
                                            "Want to Reject Post?"
                                          );
                                        }}
                                      >
                                        Reject{" "}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {post?.name && post.name.length > 35
                                    ? `${post.name.slice(0, 35)}. . .`
                                    : post?.name || "-"}
                                </td>
                                <td>{post?.link}</td>
                                <td>
                                  {
                                    <img
                                      src={`${process.env.REACT_APP_API_IMAGE_URL}${post?.images[0]}`}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  }
                                </td>
                                <td>
                                  {post?.post_categories
                                    ? post?.post_categories.name
                                    : post.other_category || "-"}
                                </td>
                                <td>
                                  <Tooltip
                                    title="Details"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate(`/admin/post/${post?._id}`)
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                            {PostList.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={12}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
