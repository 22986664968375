const { REACT_APP_API_IMAGE_URL } = process.env;

export const EnvVariable = {
  AAPNAME: "EMP",
  IMAGEURL: REACT_APP_API_IMAGE_URL,
};

export const RolePermission = {
  USERTYPE: "super_admin",
  DASHBOARD: "dashboard",
  ROLELIST: "role-list",
  ROLEADD: "role-add",
  ROLEEDIT: "role-edit",
  ROLEDELETE: "role-delete",
  ADMINLIST: "admin-list",
  ADMINADD: "admin-add",
  ADMINEDIT: "admin-edit",
  ADMINDELETE: "admin-delete",
  BLOCKUSERLIST: "block-user-list",
  REPORTUSERLIST: "report-user-list",
  USERLIST: "user-list",
  USERADD: "user-add",
  USEREDIT: "user-edit",
  USERDELETE: "user-delete",
  POSTLIST: "post-list",
  POSTEDIT: "post-edit",
};
