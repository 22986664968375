import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";

const User = () => {
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchUser, setSearchUser] = useState("");
  const [userListPagination, setuserListPagination] = useState([]);
  const navigate = useNavigate();

  const GetUserList = async () => {
    setIsLoading(true);
    await getNodeApi(
      `subadmin?offset=${page}&limit=${limit}&search=${searchUser}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.totalRecords > 0) {
          setuserListPagination(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setuserListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setuserListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        throw err;
      });
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchUser(searchValue);
    } else {
      setSearchUser("");
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetUserList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetUserList(parseInt(event.target.value, 10), 0, "");
  };

  const deleteUserById = (id) => {
    Confirmation("Want to delete user?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi("subadmin/", { id })
          .then((res) => {
            SuccessToast(res?.data?.message || "User Deleted.");
            GetUserList(limit, page, "");
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleUserStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("subadmin/change-status", postObj);
      GetUserList(limit, page, "");
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    GetUserList(limit, page, searchUser);
  }, [limit, page, searchUser]);

  return (
    <>
      <PageTitle pageTitle="SubAdmin List" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Sub Admin</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Sub Admin</li>
                </ul>
              </div>
              {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link
                    to="/admin/add-subadmin"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.ADMINADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-subadmin"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                placeholder="Search Sub Admin"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.ADMINEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}
                          {role == RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.ADMINEDIT ||
                                  hasPermission(RolePermission.ADMINDELETE)
                              ) && <th className="text-center">Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {userListPagination.map((user, index) => (
                              <tr key={index}>
                                <td>{user.first_name || "-"}</td>
                                <td>{user.last_name || "-"}</td>
                                <td>{user.email || "-"}</td>
                                <td>{user?.roles[0]?.role?.name || "-"}</td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="user"
                                    role={user}
                                    changeActive={handleUserStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.ADMINEDIT
                                    ) && (
                                      <StatusButton
                                        module="user"
                                        role={user}
                                        changeActive={handleUserStatusChange}
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-subadmin/${user._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ADMINEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-subadmin/${user._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => deleteUserById(user?._id)}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ADMINDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() =>
                                            deleteUserById(user?._id)
                                          }
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {userListPagination.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={12}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
