import { getNodeApi, postNodeApi } from "../../../Services/Api/appUtilsApi";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { customSelectStyles } from "../../../Utils/CSS/select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as Yup from "yup";
import PageTitle from "../../../Utils/PageTitle";

const AddUser = () => {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [roleList, setRoleList] = useState([]);

  const addUserInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    mobile: "",
    confirm_password: "",
    roles: "",
    is_active: true,
  };

  const addUserValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    email: Yup.string().email().required("Email is required"),
    mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required('Please enter a mobile number'),
    roles: Yup.string().required("Please select role"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .test(
        "uppderCase",
        "Must contain at least one uppercase character",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "lowerCase",
        "Must contain at least one lowercase character",
        (value) => /[a-z]/.test(value)
      )
      .test("oneNumber", "Must contain at least one number", (value) =>
        /[0-9]/.test(value)
      )
      .test(
        "oneSpecial",
        "Must contain at least one special character",
        (value) => /[!@#%&]/.test(value)
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const getRoles = async () => {
    await getNodeApi("general/get-all-roles").then((res) => {
      let roleArr = res?.data?.roles;
      roleArr = roleArr.map((value) => {
        return {
          label: value.name,
          value: value._id,
        };
      });
      setRoleList(roleArr);
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <PageTitle pageTitle="Add SubAdmin" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Sub Admin</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/sub-admin">Sub Admin</Link>
                  </li>
                  <li className="breadcrumb-item active">Add</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/sub-admin" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={addUserInitialValues}
                    validationSchema={addUserValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        email: values.email,
                        mobile: values.mobile,
                        password: values.password,
                        roles: [values.roles],
                        is_active: values.is_active,
                      };
                      await postNodeApi("subadmin/", postData)
                        .then((res) => {
                          if (res.status === 1) {
                            SuccessToast(res?.message);
                            setSubmitting(false);
                            navigate("/admin/sub-admin");
                          }
                          else {
                            ErrorToast(res?.message);
                          }
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${errors.first_name && touched.first_name
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="first_name"
                                  placeholder="Enter first name"
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${errors.last_name && touched.last_name
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="last_name"
                                  placeholder="Enter last name"
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${errors.email && touched.email
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="email"
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Mobile <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${errors.mobile && touched.mobile
                                    ? "input-error"
                                    : null
                                    }`}
                                  name="mobile"
                                  placeholder="Enter Mobile"
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    className={`form-control ${errors.password && touched.password
                                      ? "input-error"
                                      : null
                                      }`}
                                    name="password"
                                    placeholder="Enter password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${isShowPassword ? "fa-eye-slash" : "fa-eye"
                                      } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Confirm Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={
                                      isShowConfirmPassword
                                        ? "text"
                                        : "password"
                                    }
                                    className={`form-control ${errors.confirm_password &&
                                      touched.confirm_password
                                      ? "input-error"
                                      : null
                                      }`}
                                    name="confirm_password"
                                    placeholder="Confirm password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowConfirmPassword(
                                        !isShowConfirmPassword
                                      )
                                    }
                                    className={`fas ${isShowConfirmPassword
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                      } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="confirm_password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Role <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={roleList}
                                  onChange={(e) => {
                                    setFieldValue("roles", e.value);
                                  }}
                                  name="roles"
                                  placeholder="Select role"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="roles"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <SwitchToggle
                              setFieldValue={setFieldValue}
                              value={values}
                            />
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
