import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  clearAllNotificationListSearvice,
  getAllNotification,
  getUnreadNotification,
  getUnreadNotificationCount,
} from "../../Services/Api/notification";
import { getNodeApi } from "../../Services/Api/appUtilsApi";
import { EnvVariable } from "../../Utils/constance/options";
const AdminHeader = (props) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [userData, setUserData] = useState({});
  const path = useLocation();
  const navigate = useNavigate();

  const getNotificationCount = () => {
    getUnreadNotificationCount().then((res) => {
      setNotificationCount(res.data.data.unread_count);
    });
  };
  const getNotificationList = () => {
    getUnreadNotification()
      .then((res) => {
        setNotificationList(res.data.data.notifications);
      })
      .catch(() => {
        setNotificationList([]);
      });
  };
  const clearAllNotification = () => {
    clearAllNotificationListSearvice().then(() => {
      setNotificationCount(0);
    });
  };
  const GetProfile = () => {
    getNodeApi("auth/get-profile")
      .then((res) => {
        let data = res?.data || {};
        setUserData(data);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const getNotificationListFromCurrent = (id) => {
    getAllNotification(id)
      .then((res) => {
        getNotificationCount();
      })
      .catch(() => {});
  };
  useEffect(() => {
    getNotificationCount();
    GetProfile();
  }, []);

  return (
    <div>
      <div className="header-left header-left-one">
        <Link to="/admin/dashboard" className="logo">
          <img src="/inner-assets/img/logo.png" alt="Logo" />
        </Link>
      </div>
      <Link to="#" id="toggle_btn">
        <i className="fas fa-bars"></i>
      </Link>
      <Link
        to="#"
        className="mobile_btn"
        id="mobile_btn"
        onClick={() => props.onClick(!props.showSideBar)}
      >
        <i className="fas fa-bars"></i>
      </Link>
      <ul className="nav nav-tabs user-menu">
        {/* <li className="nav-item dropdown">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={() => getNotificationList()}
          >
            <i className="fa fa-bell"></i>
            <span className={ notificationCount > 0 ? "badge rounded-pill ripple" : "badge rounded-pill" }>{notificationCount}</span>
          </Link>
          <div className="dropdown-menu notifications">
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
              <Link to="#" onClick={() => clearAllNotification()} className="clear-noti">
                Clear All
              </Link>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {notificationList.length > 0 && notificationList.map((notification) => (
                  <li key={notification._id} className="notification-message">
                    <span 
                      onClick={()=>{
                        // eslint-disable-next-line
                        if(path.pathname == "/admin/notifications"){
                          getNotificationListFromCurrent(notification._id)
                         }else{
                          getNotificationListFromCurrent(notification._id)
                          navigate("/admin/notifications", {state: { id: notification._id }})
                        }
                      }}
                    >
                     <div className="d-flex my-2">
                        <div className="left-img header-notification">
                            <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || "/inner-assets/img/profiles/default_image.jpg"} alt="Profile pic" />
                        </div>
                        <div className="right-content">
                            <p className="noti-details">
                                {notification?.message}
                              </p>
                              <p className="noti-time">
                                <span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span>
                              </p>
                        </div>
                     </div>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to={{ pathname: '/admin/notifications' }}>View all Notifications</Link>
            </div>
          </div>
        </li> */}
        <li className="nav-item main-drop">
          <Link to="/admin/profile" className="nav-link">
            <span className="user-img">
              <img
                src={
                  userData?.profile_picture
                    ? `${EnvVariable.IMAGEURL}${userData.profile_picture}`
                    : "/inner-assets/img/profiles/default_image.jpg"
                }
                alt="imge"
              />
              <span className="status online"></span>
            </span>
            <span>{userData.name}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminHeader;
