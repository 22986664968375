import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "../../../Utils";
import moment from "moment";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

const Feedbacks = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFeedback, setSearchFeedback] = useState("");
  const [FeedbackList, setFeedbackList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");

  const openModal = (review) => {
    setSelectedMessage(review);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedMessage("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const GetFeedbackList = async () => {
    setIsLoading(true);
    await getNodeApi(
      `general/feedback?offset=${page}&limit=${limit}&search=${searchFeedback}`
    )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.totalRecords > 0) {
          setFeedbackList(res?.data?.feedbacks);
          setTotalRecords(res?.data?.totalRecords);
        } else {
          setFeedbackList([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setFeedbackList([]);
        setTotalRecords(0);
        setIsLoading(false);
        throw err;
      });
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchFeedback(searchValue);
    } else {
      setSearchFeedback("");
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetFeedbackList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetFeedbackList(parseInt(event.target.value, 10), 0, "");
  };

  useEffect(() => {
    GetFeedbackList(limit, page, searchFeedback);
  }, [limit, page, searchFeedback]);

  return (
    <>
      <PageTitle pageTitle="Feedback List" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Feedback</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Feedback</li>
                </ul>
              </div>
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                placeholder="Search Feedback"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Rating</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Message</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {FeedbackList.map((feedback, index) => (
                              <tr key={index}>
                                <td>
                                  {Array.from({
                                    length: 5 || 0,
                                  }).map((_, index) => (
                                    <React.Fragment key={index}>
                                      {index < feedback.stars ? (
                                        <StarIcon style={{ fill: "#7638ff" }} />
                                      ) : (
                                        <StarBorderOutlinedIcon
                                          style={{ fill: "#7638ff" }}
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                                </td>
                                <td>{feedback?.name || "-"}</td>
                                <td>{feedback?.email || "-"}</td>
                                <td>
                                  {feedback?.message &&
                                  feedback?.message.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(feedback?.message, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openModal(feedback?.message)
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    feedback?.message || "-"
                                  )}
                                </td>
                                <td>
                                  {moment(feedback?.createdAt).format(
                                    "D-MMM-yy hh:mm A"
                                  ) || "-"}
                                </td>
                              </tr>
                            ))}
                            {FeedbackList.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={12}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>Contact Us Message</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{selectedMessage}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedbacks;
