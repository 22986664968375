import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RolePermission } from "../../../Utils/constance/options";
import usePermission from "../../../Hooks/useHasPermisson";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { hasPermission, role } = usePermission();

  const GetDashboard = async () => {
    setIsLoading(true);
    await getNodeApi("general/get-dashboard-counters")
      .then((res) => {
        setIsLoading(false);
        if (res) {
          setData(res?.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setData([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetDashboard();
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard" />
      {!isLoading && (
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              {role === RolePermission.USERTYPE ? (
                <div className="col-xl-3 col-md-4 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-2">
                          <i className="fas fa-users" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/sub-admin">Admin</Link>
                          </div>
                          <div className="dash-counts">
                            <td>{data?.dashboardCounters?.admins}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.USERLIST) && (
                    <div className="col-xl-3 col-md-4 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-2">
                              <i className="fas fa-users" />
                            </span>
                            <div className="dash-count">
                              <div className="dash-title">
                                <Link to="/admin/sub-admin">Admin</Link>
                              </div>
                              <div className="dash-counts">
                                <td>{data?.dashboardCounters?.admins}</td>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {role === RolePermission.USERTYPE ? (
                <div className="col-xl-3 col-md-4 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-2">
                          <i className="fas fa-users" />
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">
                            <Link to="/admin/user">Users</Link>
                          </div>
                          <div className="dash-counts">
                            <td>{data?.dashboardCounters?.users}</td>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.USERLIST) && (
                    <div className="col-xl-3 col-md-4 col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-2">
                              <i className="fas fa-users" />
                            </span>
                            <div className="dash-count">
                              <div className="dash-title">
                                <Link to="/admin/user">Users</Link>
                              </div>
                              <div className="dash-counts">
                                <td>{data?.dashboardCounters?.users}</td>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
