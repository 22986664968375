import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Logout } from "../../routes/AdminRoutes";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import usePermission from "../../Hooks/useHasPermisson";
import { RolePermission } from "../../Utils/constance/options";

const AdminSidebar = ({ setOpenSidebar }) => {
  const location = useLocation();
  let path = location.pathname;
  const { hasPermission, role } = usePermission();
  const [hideShow, setHideShow] = useState("none");
  const [adminManagementHideShow, setAdminManagementHideShow] =
    useState("none");
  const [arrowAdminManagement, setarrowAdminManagement] =
    useState("menu-arrow");
  const [userManagementHideShow, setUserManagementHideShow] = useState("none");
  const [arrowUserManagement, setarrowUserManagement] = useState("menu-arrow");
  const [customerServiceHideShow, setCustomerServicHideShow] = useState("none");
  const [arrowCustomerService, setarrowCustomerService] =
    useState("menu-arrow");

  useEffect(() => {
    setarrowAdminManagement(
      adminManagementHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
    setarrowUserManagement(
      userManagementHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
    setarrowCustomerService(
      customerServiceHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
  });

  useEffect(() => {
    if (setOpenSidebar) {
      if (path == "/admin/sub-admin" || path == "/admin/role") {
        setAdminManagementHideShow("block");
      }
      if (path == "/admin/contact-us" || path == "/admin/feedback") {
        setCustomerServicHideShow("block");
      }
      if (
        path == "/admin/user" ||
        path == "/admin/block-user" ||
        path == "/admin/report-user"
      ) {
        setUserManagementHideShow("block");
      }
    }
  }, []);

  const menuclick = (value) => {
    setHideShow("none");
    setAdminManagementHideShow("none");
    setUserManagementHideShow("none");
    setCustomerServicHideShow("none");

    if (value == "adminmanagement") {
      setAdminManagementHideShow("block");
    }

    if (value == "usermanagement") {
      setUserManagementHideShow("block");
    }

    if (value == "customerservice") {
      setCustomerServicHideShow("block");
    }
  };

  return (
    <div
      className="sidebar"
      id="sidebar"
      style={
        setOpenSidebar == true
          ? { marginLeft: "0px" }
          : { marginLeft: "-225px" }
      }
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>

            <li onClick={() => menuclick("dashboard")}>
              <NavLink to="/admin/dashboard">
                <i data-feather="home" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {role == RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setAdminManagementHideShow(
                          adminManagementHideShow === "none" ? "block" : "none"
                        );
                      }}
                    >
                      <i data-feather="clipboard" />
                      <i className="fas fa-users icon"></i>
                      <span>Sub Admin</span>{" "}
                      <span className={arrowAdminManagement} />
                    </div>
                  </Link>
                  <ul style={{ display: adminManagementHideShow }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("adminmanagement")}
                        to="/admin/sub-admin"
                      >
                        Sub Admin
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("adminmanagement")}
                        to="/admin/role"
                      >
                        Role Management
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.ADMINLIST) ||
                  hasPermission(RolePermission.ROLELIST)) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setAdminManagementHideShow(
                            adminManagementHideShow === "none"
                              ? "block"
                              : "none"
                          );
                        }}
                      >
                        <i data-feather="clipboard" />
                        <i className="fas fa-users icon"></i>
                        <span>Sub Admin</span>{" "}
                        <span className={arrowAdminManagement} />
                      </div>
                    </Link>
                    <ul style={{ display: adminManagementHideShow }}>
                      {hasPermission(RolePermission.ADMINLIST) && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("adminmanagement")}
                            to="/admin/sub-admin"
                          >
                            Sub Admin
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.ROLELIST) && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("adminmanagement")}
                            to="/admin/role"
                          >
                            Role Management
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setUserManagementHideShow(
                          userManagementHideShow === "none" ? "block" : "none"
                        );
                      }}
                    >
                      <i data-feather="clipboard" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                      <span>User</span> <span className={arrowUserManagement} />
                    </div>
                  </Link>
                  <ul style={{ display: userManagementHideShow }}>
                    <li>
                      <NavLink
                        onClick={() => menuclick("usermanagement")}
                        to="/admin/user"
                      >
                        User
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("usermanagement")}
                        to="/admin/block-user"
                      >
                        Block User
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => menuclick("usermanagement")}
                        to="/admin/report-user"
                      >
                        Report User
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.USERLIST) ||
                  hasPermission(RolePermission.BLOCKUSERLIST) ||
                  hasPermission(RolePermission.REPORTUSERLIST)) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setUserManagementHideShow(
                            userManagementHideShow === "none" ? "block" : "none"
                          );
                        }}
                      >
                        <i data-feather="clipboard" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-users"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span>User</span>{" "}
                        <span className={arrowUserManagement} />
                      </div>
                    </Link>
                    <ul style={{ display: userManagementHideShow }}>
                      {hasPermission(RolePermission.USERLIST) && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("usermanagement")}
                            to="/admin/user"
                          >
                            User
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.BLOCKUSERLIST) && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("usermanagement")}
                            to="/admin/block-user"
                          >
                            Block User
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.REPORTUSERLIST) && (
                        <li>
                          <NavLink
                            onClick={() => menuclick("usermanagement")}
                            to="/admin/report-user"
                          >
                            Report User
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <>
                <li>
                  <NavLink to="/admin/post">
                    <i data-feather="post" />
                    <i className="fas fa-images icon"></i>
                    <span>Post</span>
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                {hasPermission(RolePermission.POSTLIST) && (
                  <li>
                    <NavLink to="/admin/post">
                      <i data-feather="post" />
                      <i className="fas fa-images icon"></i>
                      <span>Post</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

            <li className="submenu">
              <Link to="#">
                <div
                  onClick={() => {
                    setCustomerServicHideShow(
                      customerServiceHideShow === "none" ? "block" : "none"
                    );
                  }}
                >
                  <i data-feather="clipboard" />
                  <i className="fas fa-comment-dots icon"></i>
                  <span>Customer Service</span>{" "}
                  <span className={arrowCustomerService} />
                </div>
              </Link>
              <ul style={{ display: customerServiceHideShow }}>
                <li>
                  <NavLink
                    onClick={() => menuclick("customerservice")}
                    to="/admin/contact-us"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => menuclick("customerservice")}
                    to="/admin/feedback"
                  >
                    Feedbacks
                  </NavLink>
                </li>
              </ul>
            </li>

            <li onClick={() => menuclick("profile")}>
              <NavLink to="/admin/profile">
                <i data-feather="user" />
                <ManageAccountsIcon />
                <span>Profile</span>
              </NavLink>
            </li>

            <li onClick={() => menuclick("logout")}>
              <NavLink onClick={Logout} to="/admin/logout">
                <i data-feather="log-out" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebar;
