import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { getNodeApi, postNodeApi } from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";

const AddRoleManagement = () => {
  const signInSchema = Yup.object().shape({
    name: Yup.string().required("Role name is required"),
    description: Yup.string().required("Description is required"),
    rights: Yup.array()
      .required("Please select at least one permission")
      .min(1, "Please select at least one permission"),
  });

  const initialValues = {
    name: "",
    description: "",
    rights: [],
    is_active: true,
  };

  const navigate = useNavigate();
  const [resourceList, setResourceList] = useState([]);

  const getResource = async () => {
    await getNodeApi("/permissions/get-all-permissions")
      .then((res) => {
        let resorceArr = res?.data || {};
        resorceArr = resorceArr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        setResourceList(resorceArr);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  useEffect(() => {
    getResource();
  }, []);

  return (
    <>
      <PageTitle pageTitle="Add Role Management" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Role &amp; Permissions</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/role">Role &amp; Permissions</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Add Role &amp; Permissions
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/role" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Role Information</h5>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const rights = [];
                      for (let i = 0; i < values.rights.length; i++) {
                        rights.push(values.rights[i]);
                      }
                      const postData = {
                        name: values.name,
                        description: values.description,
                        permissions: rights,
                        is_active: values.is_active,
                      };
                      await postNodeApi("/role", postData)
                        .then((res) => {
                          if (res.status === 1) {
                            SuccessToast(res?.message);
                          } else {
                            ErrorToast(res?.message);
                          }
                          setSubmitting(false);
                          navigate("/admin/role");
                          resetForm();
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        values,
                        setFieldValue,
                        errors,
                        touched,
                        isSubmitting,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Role Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.rname
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter role name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  placeholder="Enter description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <h5 className="role_title">
                            Permissions Information{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="row mx-3 my-4">
                            {resourceList.map((resource, index) => (
                              <div className="col-md-3" key={index}>
                                <div className="form-group">
                                  <label className="custom_check">
                                    <Field
                                      type="checkbox"
                                      className={`form-control ${
                                        errors.resource?.label &&
                                        touched.resource?.label
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="rights"
                                      value={resource?.value}
                                    />
                                    <span className="checkmark" />
                                    <label>{resource?.label}</label>
                                  </label>
                                </div>
                              </div>
                            ))}
                            <ErrorMessage
                              name="rights"
                              component="span"
                              className="error"
                            />
                          </div>
                          <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Role
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRoleManagement;
