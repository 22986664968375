import axios from "axios";
import handleError from "./handleError";

/**
 * node api base url
 */
const nodeBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

/**
 * Get auth token from local storage
 */
const getToken = () => {
  return localStorage.getItem("accessToken");
};

/**
 * Get headers for requests
 * @param {Boolean} isMultiPart = true=multiplart/form-data or false=application/json
 */
export const getHeaders = (isMultiPart = false) => {
  return {
    headers: {
      Accept: "application/json",
      "content-Type": isMultiPart ? "multipart/form-data" : "application/json",
      ...(getToken() && {
        authorization: `${getToken()}`,
      }),
    },
  };
};

/**
 * Handles get request that sent to server
 * @param {String} path = url of the api
 * @return {Promise} success promise that will be available in then block or error that will be available in catch block of api call
 */
export const getNodeApi = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${nodeBaseUrl}${path}`, getHeaders())
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

/**
 * Handles post request that sent to server
 * @param {String} path = url of the api
 * @param {Object} data = Json object with key/value that sent to server
 * @param {Boolean} isMultiPart = true=multiplart/form-data or false=application/json
 * @return {Promise} success promise that will be available in then block or error that will be available in catch block of api call
 */
export const postNodeApi = async (path, data, isMultiPart = false) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${nodeBaseUrl}${path}`, data, getHeaders(isMultiPart))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

/**
 * Handles put request that sent to server
 * @param {String} path = url of the api
 * @param {Object} data = Json object with key/value that sent to server
 * @param {Boolean} isMultiPart = true=multiplart/form-data or false=application/json
 * @return {Promise} success promise that will be available in then block or error that will be available in catch block of api call
 */
export const putNodeApi = (path, data, isMultiPart = false) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${nodeBaseUrl}${path}`, data, getHeaders(isMultiPart))
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

/**
 * Handles delete request that sent to server
 * @param {String} path = url of the api
 * @param {Object} data = Json object with key/value that sent to server
 * @return {Promise} success promise that will be available in then block or error that will be available in catch block of api call
 */
export const deleteNodeApi = (path, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${nodeBaseUrl}${path}`, {
        ...getHeaders(),
        data,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

// Handles 401

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("adminPermission");
      localStorage.removeItem("userType");
      window.location.assign("/");
    }
    return Promise.reject(error);
  }
);
