import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import { EnvVariable}from '../../../Utils/constance/options'
import PageTitle from "../../../Utils/PageTitle";

const PostDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [lightboxActive, setLightboxActive] = useState(false);

  const getPost = async () => {
    setIsLoading(true);
    await getNodeApi(`post/${id}`)
      .then((res) => {
        setIsLoading(false);
        if (res?.data) {
          setPost(res?.data);
          setPostImages(res?.data.images);
          setActiveImageIndex(0);
        } else {
          setPost([]);
        }
      })
      .catch((err) => {
        setPost([]);
        setIsLoading(false);
        throw err;
      });
  };

  const showLightbox = (index) => {
    setActiveImageIndex(index);
    setLightboxActive(true);
  };

  const hideLightbox = () => {
    setLightboxActive(false);
  };

  const transitionSlidesLeft = () => {
    setActiveImageIndex(
      (prevIndex) => (prevIndex - 1 + postImages.length) % postImages.length
    );
  };

  const transitionSlidesRight = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % postImages.length);
  };

  useEffect(() => {
    getPost();
  }, []);

  return (
    <>
      <PageTitle pageTitle="Post Details" />
      {!isLoading && (
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Post Details</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/admin/post">Post</Link>
                    </li>
                    <li className="breadcrumb-item active">Post Details</li>
                  </ul>
                </div>
                <div className="col-auto">
                  <Link to="/admin/post" className="btn btn-primary me-1">
                    <i className="fas fa-angle-double-left" /> Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-md-flex justify-content-between align-items-center">
                      <h5 className="card-title text-uppercase">
                        {post?.name}
                      </h5>
                      <a
                        href={post?.link}
                        target="_blank"
                        rel="noreferrer"
                        className="card-title"
                      >
                        Visit Link
                      </a>
                    </div>
                    <hr />
                    <h6>Description</h6>
                    <p className="card-text">{post?.description}</p>
                    <h6>Additional Information</h6>
                    <p className="card-text">{post?.additional_data}</p>
                    <hr />
                    <h6>Image Gallery</h6>
                    <section className="gallery mt-2">
                      <section className="p-0 container">
                        <div className="row d-flex">
                          {postImages.map((image, index) => (
                            <div key={index} className="col-12 col-md-4 p-1">
                              <div className="lightbox_img_wrap">
                                <img
                                  className="lightbox-enabled"
                                  src={`${EnvVariable.IMAGEURL}${image}`}
                                  onClick={() => showLightbox(index)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    </section>
                    {lightboxActive && (
                      <section className="lightbox-container active">
                        {activeImageIndex !== 0 && (
                          <span
                            className="material-symbols-outlined material-icons lightbox-btn left"
                            id="left"
                            onClick={transitionSlidesLeft}
                          >
                            arrow_back
                          </span>
                        )}
                        {activeImageIndex !== postImages.length - 1 && (
                          <span
                            className="material-symbols-outlined material-icons lightbox-btn right"
                            id="right"
                            onClick={transitionSlidesRight}
                          >
                            arrow_forward
                          </span>
                        )}
                        <span
                          id="close"
                          className="close material-icons material-symbols-outlined"
                          onClick={hideLightbox}
                        >
                          close
                        </span>
                        <div className="lightbox-image-wrapper">
                          <img
                            alt="lightboximage"
                            className="lightbox-image"
                            src={`${EnvVariable.IMAGEURL}${postImages[activeImageIndex]}`}
                          />
                        </div>
                      </section>
                    )}
                    <hr />
                    <div className="d-md-flex justify-content-between">
                      <p>
                        <p className="card-text">
                          <strong>Created By:</strong>{" "}
                          {post?.created_by?.full_name}{" "}
                        </p>
                        <p className="card-text">
                          <strong>Close Date:</strong>{" "}
                          {moment(post?.close_date).format("D-MMM-yy hh:mm A")}{" "}
                        </p>
                      </p>
                      <p>
                        <p className="card-text">
                          <strong>Category:</strong>{" "}
                          {post?.post_categories
                            ? post?.post_categories.name
                            : post.other_category}
                        </p>
                        {post.reason && (
                          <p className="card-text">
                            <strong>Reject Reason:</strong> {post?.reason}
                          </p>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PostDetails;
