import { Route, Routes, useNavigate } from "react-router-dom";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import {
  AddRoleManagement,
  EditRolePermission,
  Role,
} from "../Components/Admin/RoleManagement";
import {
  Admin,
  AddAdmin,
  EditAdmin,
} from "../Components/Admin/AdminManagement";
import { User, BlockUser, ReportUser } from "../Components/Admin/User";
import { Post, PostDetail } from "../Components/Admin/Post";
import { Dashboard } from "../Components/Admin/Dashboard";
import { ChangePassword, Settings } from "../Components/Admin/settings";
import { useState } from "react";
import { PermissionAlert } from "../Components/Admin/PermissionAlert";
import { Logout } from "./AdminRoutes";
import usePermission from "../Hooks/useHasPermisson";
import { RolePermission } from "../Utils/constance/options";
import { ContactUs, Feedbacks } from "../Components/Admin/CustomerServices";

const AdminRoutesPermission = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { hasPermission, role, token } = usePermission();

  useEffect(() => {
    setLoading(false);
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <AdminLayout />
      {!loading && (
        <Routes>
          <Route
            exact
            path="/dashboard"
            element={<PrivateRoute Component={Dashboard} />}
          />

          <Route
            exact
            path="/profile"
            element={<PrivateRoute Component={Settings} />}
          />

          <Route
            exact
            path="/setting/change-password"
            element={<PrivateRoute Component={ChangePassword} />}
          />

          <Route exact path="/logout" element={<Logout />} />

          <Route
          exact
          path="/contact-us"
          element={<PrivateRoute Component={ContactUs} />}
        />

        <Route
          exact
          path="/feedback"
          element={<PrivateRoute Component={Feedbacks} />}
        />

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/sub-admin"
              element={<PrivateRoute Component={Admin} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ADMINLIST) ? (
                <Route
                  exact
                  path="/sub-admin"
                  element={<PrivateRoute Component={Admin} />}
                />
              ) : (
                <Route exact path="/sub-admin" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-subadmin"
              element={<PrivateRoute Component={AddAdmin} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ADMINADD) ? (
                <Route
                  exact
                  path="/add-subadmin"
                  element={<PrivateRoute Component={AddAdmin} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-subadmin"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-subadmin/:id"
              element={<PrivateRoute Component={EditAdmin} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ADMINEDIT) ? (
                <Route
                  exact
                  path="/edit-subadmin/:id"
                  element={<PrivateRoute Component={EditAdmin} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-subadmin/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/user"
              element={<PrivateRoute Component={User} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.USERLIST) ? (
                <Route
                  exact
                  path="/user"
                  element={<PrivateRoute Component={User} />}
                />
              ) : (
                <Route exact path="/user" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/block-user"
              element={<PrivateRoute Component={BlockUser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.BLOCKUSERLIST) ? (
                <Route
                  exact
                  path="/block-user"
                  element={<PrivateRoute Component={BlockUser} />}
                />
              ) : (
                <Route exact path="/block-user" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/report-user"
              element={<PrivateRoute Component={ReportUser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.REPORTUSERLIST) ? (
                <Route
                  exact
                  path="/report-user"
                  element={<PrivateRoute Component={ReportUser} />}
                />
              ) : (
                <Route
                  exact
                  path="/report-user"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/post"
              element={<PrivateRoute Component={Post} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.REPORTUSERLIST) ? (
                <Route
                  exact
                  path="/post"
                  element={<PrivateRoute Component={Post} />}
                />
              ) : (
                <Route exact path="/post" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/post/:id"
              element={<PrivateRoute Component={PostDetail} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.REPORTUSERLIST) ? (
                <Route
                  exact
                  path="/post/:id"
                  element={<PrivateRoute Component={PostDetail} />}
                />
              ) : (
                <Route exact path="/post/:id" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/role"
              element={<PrivateRoute Component={Role} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ROLELIST) ? (
                <Route
                  exact
                  path="/role"
                  element={<PrivateRoute Component={Role} />}
                />
              ) : (
                <Route exact path="/role" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-role-management"
              element={<PrivateRoute Component={AddRoleManagement} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ROLEADD) ? (
                <Route
                  exact
                  path="/add-role-management"
                  element={<PrivateRoute Component={AddRoleManagement} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-role-management"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-role-permission/:id"
              element={<PrivateRoute Component={EditRolePermission} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ROLEEDIT) ? (
                <Route
                  exact
                  path="/edit-role-permission/:id"
                  element={<PrivateRoute Component={EditRolePermission} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-role-permission/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}
        </Routes>
      )}
    </>
  );
};
export default AdminRoutesPermission;
