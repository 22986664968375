import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import PageTitle from "../../../Utils/PageTitle";

const User = () => {
  const navigate = useNavigate();
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchUser, setsearchUser] = useState("");
  const [UserListPagination, setUserListPagination] = useState([]);

  const GetUserList = async () => {
    setIsLoading(true);
    await getNodeApi(`user?offset=${page}&limit=${limit}&search=${searchUser}`)
      .then((res) => {
        setIsLoading(false);
        if (res?.totalRecords > 0) {
          setUserListPagination(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setUserListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setUserListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        throw err;
      });
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setsearchUser(searchValue);
    } else {
      setsearchUser("");
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetUserList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetUserList(parseInt(event.target.value, 10), 0, "");
  };

  const deleteUserById = (id) => {
    Confirmation("Want to delete User?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi("user/", { id })
          .then((res) => {
            SuccessToast(res?.data?.message || "User Deleted.");
            GetUserList(limit, page, "");
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleUserStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("user/change-status", postObj);
      GetUserList(limit, page, "");
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    GetUserList(limit, page, searchUser);
  }, [limit, page, searchUser]);

  return (
    <>
      <PageTitle pageTitle="User List" />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">User</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">User</li>
                </ul>
              </div>
              {/* {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link
                    to="/admin/add-user"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add User
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.USERADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-user"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add User
                      </Link>
                    </div>
                  )}
                </>
              )} */}
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                placeholder="Search User"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Country</th>
                          <th>State</th>
                          <th>City</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.USEREDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}
                          {/* {role == RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.CUSTOMEREDIT ||
                                hasPermission(RolePermission.CUSTOMERDELETE)
                              ) && <th className="text-center">Action</th>}
                            </>
                          )} */}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {UserListPagination.map((user, index) => (
                              <tr key={index}>
                                <td>{user?.first_name || "-"}</td>
                                <td>{user?.last_name || "-"}</td>
                                <td>{user?.email || "-"}</td>
                                <td>{user?.mobile || "-"}</td>
                                <td>{user?.country?.name || "-"}</td>
                                <td>{user?.state?.name || "-"}</td>
                                <td>{user?.city?.name || "-"}</td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="User"
                                    role={user}
                                    changeActive={handleUserStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(RolePermission.USEREDIT) && (
                                      <StatusButton
                                        module="User"
                                        role={user}
                                        changeActive={handleUserStatusChange}
                                      />
                                    )}
                                  </>
                                )}
                                {/* <td>
                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() => navigate(`/admin/edit-customer/${customer._id}`)}
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.CUSTOMEREDIT
                                      ) && (
                                          <Tooltip
                                            title="Edit"
                                            className="btn btn-sm btn-white text-success me-2"
                                            onClick={() => navigate(`/admin/edit-customer/${customer._id}`)}
                                          >
                                            <IconButton>
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                    </>
                                  )}

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => deleteUserById(customer?._id)}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.CUSTOMERDELETE
                                      ) && (
                                          <Tooltip
                                            title="Delete"
                                            className="btn btn-sm btn-white text-danger me-2"
                                            onClick={() => deleteUserById(customer?._id)}
                                          >
                                            <IconButton>
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                    </>
                                  )}
                                </td> */}
                              </tr>
                            ))}
                            {UserListPagination.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={12}>
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
